export const trackHomePageVisit = (pathname) => {
  if (typeof window !== 'undefined' && window._kmq) {
    console.log('trackHomePageVisit', true);
    window._kmq.push(['record', 'Visited Website', { path: pathname }]);
  } else {
    console.log('trackHomePageVisit', false);
  }
};

export const trackBookCallPageVisit = (pathname) => {
  if (typeof window !== 'undefined' && window._kmq) {
    console.log('trackBookCallPageVisit', true);
    window._kmq.push(['record', 'Booked Call', { path: pathname }]);
  } else {
    console.log('trackBookCallPageVisit', false);
  }
};

export const trackVisitedCalendlyPageVisit = (pathname) => {
  if (typeof window !== 'undefined' && window._kmq) {
    console.log('trackVisitedCalendlyPageVisit', true);
    window._kmq.push(['record', 'Visited Calendly Page', { path: pathname }]);
  } else {
    console.log('trackVisitedCalendlyPageVisit', false);
  }
};

export const trackVisitedSignUpButtonClick = (buttonName) => {
  if (typeof window !== 'undefined' && window._kmq) {
    console.log('trackVisitedSignUpButtonClick', true);
    window._kmq.push([
      'record',
      'Visited Sign-Up Page',
      { button: buttonName },
    ]);
  } else {
    console.log('trackVisitedSignUpButtonClick', false);
  }
};
