import React from 'react';
import PropTypes from 'prop-types';
import Style from './Implementation.module.scss';
import { RichText } from 'prismic-reactjs';
import cross from '../../images/cross.svg';
import check from '../../images/check.svg';

const Implementation = ({ primary, items }) => {
  const { heading } = primary;

  return (
    <div className={Style.Implementation}>
      <div className={Style.accordion}>
        <div className={Style.accordionItem}>
          <div className={Style.heading}>
            <RichText render={heading.richText} />
          </div>
          <div className={Style.content}>
            {items?.map((item, index) => {
              return (
                <>
                  <div
                    className={
                      index % 2 === 0
                        ? Style.featuretitleRow
                        : Style.featureTitleRow
                    }
                    key={index}
                  >
                    <div className={Style.featuretitle}>
                      <RichText render={item?.feature_title?.richText} />
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_free_plan === true ? (
                        <>
                          <img src={`${check}`} alt="check" />
                          <RichText render={item?.text?.richText} />
                        </>
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_small_plan === true ? (
                        <>
                          <img src={`${check}`} alt="check" />
                          <RichText render={item?.text?.richText} />
                        </>
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_business_plan === true ? (
                        <>
                          {!item.text.richText[0]?.text && (
                            <img src={`${check}`} alt="check" />
                          )}
                          <RichText render={item?.text?.richText} />
                        </>
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_advance_plan === true ? (
                        <>
                          {!item.text.richText[0]?.text && (
                            <img src={`${check}`} alt="check" />
                          )}
                          <RichText render={item?.text?.richText} />
                        </>
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

Implementation.propTypes = {
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default Implementation;
