import React from 'react';
import PropTypes from 'prop-types';
import style from './Onboarding.module.scss';
import { navigate } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Button, { VARIANT } from '@components/Button/Button.js';

const OnboardingPage = ({ data }) => {
  const { title, description } = data;
  const { body } = data;
  const allOnboardingdata = body[0];

  const handleClick = (e, buttonLink) => {
    e.preventDefault();
    navigate(`${buttonLink}`);
  };

  return (
    <div className={style.boardPage}>
      <section className={style.hero3}>
        <div className={style.wrapper}>
          <div className={style.title}>
            <RichText render={title?.richText} />
          </div>
          <div className={style.description}>
            <RichText render={description?.richText} />
          </div>
        </div>
        {allOnboardingdata &&
          allOnboardingdata?.items?.map((item, index) => {
            return (
              <div
                key={index}
                className={style.content}
                onClick={(e) => handleClick(e, item?.buttonlink?.url)}
              >
                <div className={style.allcontent}>
                  <div className={style.paragraph}>
                    <RichText render={item?.paragraph?.richText} />
                    <Button
                      variant={VARIANT.GRADIENT}
                      isHeader={true}
                      click={(e) => handleClick(e, item?.buttonlink?.url)}
                    >
                      <RichText render={item?.buttontext?.richText} />
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
      </section>
    </div>
  );
};

export default OnboardingPage;

OnboardingPage.propTypes = {
  data: PropTypes.object.isRequired,
};
