import React from 'react';
import Questions from '@components/Questions/Questions';
import style from './Questions.module.scss';
import PropTypes from 'prop-types';

const Component = ({ primary, items, isMobile }) => {
  return (
    <div className={style.wrapper}>
      {!isMobile && <Questions primary={primary} items={items.slice(0, 8)} />}
      {isMobile && <Questions primary={primary} items={items.slice(8, 17)} />}
    </div>
  );
};

Component.propTypes = {
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
};

export default Component;
