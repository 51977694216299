import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '@components/Layout';
import OnboardingPage from '../scenes/OnboardingPage';

const Page = ({ data }) => {
  const onboardingpageContent = data?.allPrismicOnboarding?.edges[0];
  if (!onboardingpageContent) return null;
  const onBoardPage = onboardingpageContent?.node;
  const {
    uid,
    id,
    lang,
    type,
    alternate_languages,
    data: pageData,
  } = onBoardPage;

  const { metatitle, metadescription, canonical } = pageData;
  const activeDocMeta = { id, uid, lang, type, alternate_languages };

  return (
    <Layout
      activeDocMeta={activeDocMeta}
      metatitle={metatitle}
      metadescription={metadescription}
      canonical={canonical}
    >
      <OnboardingPage
        data={pageData}
        metatitle={metatitle}
        canonical={canonical}
      />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allPrismicOnboarding {
      edges {
        node {
          id
          uid
          type
          lang
          alternate_languages {
            id
            lang
            type
            uid
          }
          data {
            canonical {
              richText
            }
            description {
              richText
            }
            metadescription {
              richText
            }
            metatitle {
              richText
            }
            title {
              richText
            }
            body {
              ... on PrismicOnboardingDataBodyOnboardingMain {
                id
                items {
                  paragraph {
                    richText
                  }
                  buttontext {
                    richText
                  }
                  buttonlink {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Page;
