import React from 'react';
import PropTypes from 'prop-types';
import Style from './Support.module.scss';
import { RichText } from 'prismic-reactjs';
import cross from '../../images/cross.svg';
import check from '../../images/check.svg';

const Support = ({ primary, items }) => {
  const {
    heading,
    feature_title,
    for_free_plan,
    for_small_plan,
    for_business_plan,
    for_advance_plan,
  } = primary;

  return (
    <div className={Style.Support}>
      <div className={Style.heading}>
        <RichText render={heading.richText} />
      </div>
      <div className={Style.accordion}>
        {items?.slice(0, 1)?.map((item, index) => {
          return (
            <>
              <div
                className={
                  index % 2 === 0
                    ? Style.featuretitleRow
                    : Style.featuretitleRow
                }
                key={index}
              >
                <div className={Style.featuretitle}>
                  <RichText render={item?.feature_title?.richText} />
                </div>
                <div className={Style.lawsforplan}>
                  {item?.for_free_plan === true ? (
                    <img src={`${check}`} alt="check" />
                  ) : (
                    <img src={`${cross}`} alt="cross" />
                  )}
                </div>
                <div className={Style.lawsforplan}>
                  {item?.for_small_plan === true ? (
                    <img src={`${check}`} alt="check" />
                  ) : (
                    <img src={`${cross}`} alt="cross" />
                  )}
                </div>
                <div className={Style.lawsforplan}>
                  {item?.for_business_plan === true ? (
                    <img src={`${check}`} alt="check" />
                  ) : (
                    <img src={`${cross}`} alt="cross" />
                  )}
                </div>
                <div className={Style.lawsforplan}>
                  {item?.for_advance_plan === true ? (
                    <img src={`${check}`} alt="check" />
                  ) : (
                    <img src={`${cross}`} alt="cross" />
                  )}
                </div>
              </div>
            </>
          );
        })}
        <div className={Style.featureTitleRow}>
          <div className={Style.featuretitle}>
            <RichText render={feature_title.richText} />
          </div>
          <div className={Style.lawsforplan}>
            <RichText render={for_free_plan.richText} />
          </div>
          <div className={Style.lawsforplan}>
            <RichText render={for_small_plan.richText} />
          </div>
          <div className={Style.lawsforplan}>
            <RichText render={for_business_plan.richText} />
          </div>
          <div className={Style.lawsforplan}>
            <RichText render={for_advance_plan.richText} />
          </div>
        </div>
        {items?.slice(1, 4)?.map((item, index) => {
          return (
            <>
              <div
                className={
                  index % 2 === 1
                    ? Style.featureTitleRow
                    : Style.featuretitleRow
                }
                key={index}
              >
                <div className={Style.featuretitle}>
                  <RichText render={item?.feature_title?.richText} />
                </div>
                <div className={Style.lawsforplan}>
                  {item?.for_free_plan === true ? (
                    <img src={`${check}`} alt="check" />
                  ) : (
                    <img src={`${cross}`} alt="cross" />
                  )}
                </div>
                <div className={Style.lawsforplan}>
                  {item?.for_small_plan === true ? (
                    <img src={`${check}`} alt="check" />
                  ) : (
                    <img src={`${cross}`} alt="cross" />
                  )}
                </div>
                <div className={Style.lawsforplan}>
                  {item?.for_business_plan === true ? (
                    <img src={`${check}`} alt="check" />
                  ) : (
                    <img src={`${cross}`} alt="cross" />
                  )}
                </div>
                <div className={Style.lawsforplan}>
                  {item?.for_advance_plan === true ? (
                    <img src={`${check}`} alt="check" />
                  ) : (
                    <img src={`${cross}`} alt="cross" />
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

Support.propTypes = {
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default Support;
