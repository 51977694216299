import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import classnames from 'classnames';
import { useScrollDirection, useBreakpoints } from '@hooks';
import Dashboard from './components/Dashboard';
import PlanSwitcher from './components/PlanSwitcher';
import style from './TariffPlans.module.scss';
import BussinessCardsSwitcher from './components/BussinessCardsSwitcher';
import { globalHistory as history } from '@reach/router';
import { navigate } from 'gatsby';

const MOBILE_VIEW = 1220;

const TariffPlans = ({
  primary,
  items,
  isBarShowing,
  showBar,
  hideBar,
  scrollableRef,
  setActive,
  sliderPlans,
  businessToggle,
  isMobilePlan,
  selectCurrency,
  currency,
  isAnnual,
  setIsAnnual,
}) => {
  const { location } = history;
  const laws = [
    {
      title: primary.firstlawtitle.text,
      location: primary.firstlawlocation.text,
    },
    {
      title: primary.secondlawtitle.text,
      location: primary.secondlawlocation.text,
    },
    {
      title: primary.thirdlawtitle.text,
      location: primary.thirdlawlocation.text,
    },
  ];
  const [cardsSelected, setCardsSelected] = useState(false);
  const [selectedPlansIndexes, setSelectedPlansIndexes] = useState([0]);
  const [selectedPlans, setSelectedPlans] = useState([
    primary.firstlawtitle.text,
  ]);
  const scrollDirection = useScrollDirection();
  const [isMobile, setIsMobile] = useState(false);
  const { width } = useBreakpoints();
  const [itemsBusinessToggle, setItemBusinessToggle] = useState([]);
  const [itemsCards, setItemsCards] = useState(items.slice(0, 4));

  useEffect(() => {
    const mobile = width < MOBILE_VIEW;
    setIsMobile(mobile);
  }, [width]);

  useEffect(() => {
    if (businessToggle && businessToggle.length > 0) {
      setItemBusinessToggle(businessToggle);
    }
  }, [sliderPlans, businessToggle]);

  useEffect(() => {
    setCardsSelected(location.hash.includes('enterprise') ? true : false);
    setItemsCards(items);
  }, [location]);

  const selectPlan = (value) => {
    const isSelected = selectedPlansIndexes.includes(value);
    let indexes;
    if (isSelected) {
      indexes =
        selectedPlansIndexes.length === 1
          ? [0]
          : selectedPlansIndexes.filter((item) => item !== value);
    } else {
      indexes = [...selectedPlansIndexes, value].sort((a, b) => a - b);
    }
    setSelectedPlansIndexes(indexes);
    const plans = indexes.map((index) => laws[index].title);
    setSelectedPlans(plans);
  };

  const togglePeriod = () => {
    setIsAnnual(!isAnnual);
  };

  const toggleBussinessCards = () => {
    setCardsSelected(!cardsSelected);
    setItemsCards(cardsSelected ? items.slice(0, 4) : items.slice(4, 6));
    navigate(
      `${location.pathname}#${cardsSelected ? 'business' : 'enterprise'}`
    );
  };

  return (
    <Waypoint onEnter={hideBar} onLeave={showBar}>
      <div className={style.wrapper}>
        <div
          className={classnames(style.bar, {
            [style.disabled]:
              !isBarShowing || scrollDirection === 'up' || isMobile,
          })}
        ></div>
        <>
          <div className={style.bussiness_card_switcher_wrap}>
            <div className={style.bussiness_card_switcher}>
              <BussinessCardsSwitcher
                isAnnual={isAnnual}
                togglePeriod={togglePeriod}
                primary={primary}
                businessToggle={itemsBusinessToggle}
              />
            </div>
            <div className={style.currency_switch_wrapper}>
              <PlanSwitcher
                plans={laws}
                selectedPlans={selectedPlansIndexes}
                onSelect={selectPlan}
                selectCurrency={selectCurrency}
                currency={currency}
              />
            </div>
          </div>
          <div
            className={classnames(style.body, {
              [style.bodyforMobile]: isMobilePlan,
            })}
          >
            <div
              className={classnames(style.main, {
                [style.mainforMobile]: isMobilePlan,
              })}
              onScroll={(event) => setActive(event.target.scrollLeft)}
              ref={scrollableRef}
            >
              <Dashboard
                isAnnual={isAnnual}
                selectedPlans={selectedPlans}
                primary={primary}
                fields={itemsCards}
                currency={currency}
                isMobile={isMobilePlan}
                toggleBussinessCards={toggleBussinessCards}
              />
            </div>
          </div>
        </>
      </div>
    </Waypoint>
  );
};

TariffPlans.propTypes = {
  businessToggle: PropTypes.array.isRequired,
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  isBarShowing: PropTypes.bool.isRequired,
  showBar: PropTypes.func.isRequired,
  hideBar: PropTypes.func.isRequired,
  activepoint: PropTypes.number.isRequired,
  scrollableRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  setActiveOnClick: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
  sliderPlans: PropTypes.any,
  isMobilePlan: PropTypes.bool,
  selectCurrency: PropTypes.any,
  currency: PropTypes.any,
  setIsAnnual: PropTypes.any,
  isAnnual: PropTypes.any,
};

export default TariffPlans;
