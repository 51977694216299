import React from 'react';
import PropTypes from 'prop-types';
import style from './Onboardingcookie.module.scss';
import { RichText } from 'prismic-reactjs';
import Button from '@components/Button/Button.js';
import { navigate } from 'gatsby';

const OnboardingCookiePage = ({ data, videoask }) => {
  const { body } = data;
  const firstBodyItem = body[0];
  const {
    title,
    description,
    buttontext1,
    buttonlink1,
    buttontext2,
    buttonlink2,
  } = firstBodyItem?.primary;
  const buttonLink = buttonlink1?.text;
  const buttonLink1 = buttonlink2?.text;

  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/`);
  };

  const handleClick1 = (e) => {
    e.preventDefault();
    navigate('/onboarding');
  };
  return (
    <div className={style.cookiePage}>
      <section className={style.hero4}>
        <div className={style.wrapper1}>
          <div className={style.title}>
            <RichText render={title?.richText} />
          </div>
          <div className={style.redirect}>
            <Button isHeader={true} click={(e) => handleClick(e, buttonLink)}>
              <RichText render={buttontext1?.richText} />
            </Button>
            <span className={style.breadcums}>&gt; &gt;</span>
            <Button isHeader={true} click={(e) => handleClick1(e, buttonLink1)}>
              <RichText render={buttontext2?.richText} />
            </Button>
          </div>
        </div>
        <div className={style.imageOnWrapper}>
          <div className={style.onboardingvideoWrapper}>
            <iframe
              src={videoask?.raw?.url}
              title="Onboarding Video"
              role="presentation"
              width="100%"
              height="360"
              frameBorder="0"
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              allowFullScreen
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.allcontent}>
            <div className={style.description}>
              <RichText render={description?.richText} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OnboardingCookiePage;

OnboardingCookiePage.propTypes = {
  data: PropTypes.object.isRequired,
  videoask: PropTypes.object.isRequired,
};
