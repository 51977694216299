import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '@components/Layout';
import ResellerPageNew from '../scenes/ResellerPageNew';

const PricingResellerPageNew = ({ data }) => {
  const resellerpageContent = data.allPrismicPricesresellerpagenew.edges[0];
  if (!resellerpageContent) return null;
  const resellerpage = resellerpageContent.node;
  const {
    uid,
    id,
    type,
    alternate_languages,
    lang,
    data: pageData,
  } = resellerpage;
  const activeDocMeta = { id, uid, lang, type, alternate_languages };
  const { metatitle, metadescription, canonical, body: pageContent } = pageData;

  return (
    <>
      <Layout
        activeDocMeta={activeDocMeta}
        metatitle={metatitle}
        metadescription={metadescription}
        canonical={canonical}
      >
        <ResellerPageNew
          content={pageContent}
          canonical={canonical}
          metatitle={metatitle}
        />
      </Layout>
    </>
  );
};

PricingResellerPageNew.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($uid: String, $lang: String) {
    allPrismicPricesresellerpagenew(
      filter: { uid: { eq: $uid }, lang: { eq: $lang } }
    ) {
      edges {
        node {
          uid
          type
          id
          lang
          alternate_languages {
            id
            lang
            uid
            type
          }
          data {
            metatitle {
              text
            }
            metadescription {
              text
            }
            canonical {
              text
            }
            body {
              ... on PrismicPricesresellerpagenewDataBodyHeroNew {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  subtitle {
                    richText
                  }
                  description {
                    richText
                  }
                  buttontext {
                    richText
                  }
                  buttonlink {
                    richText
                  }
                  previewimage {
                    url
                  }
                }
              }
              ... on PrismicPricesresellerpagenewDataBodyCardView {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  button_text {
                    richText
                  }
                  button_link {
                    url
                  }
                }
                items {
                  card_title {
                    richText
                  }
                  card_sub_title {
                    richText
                  }

                  card_image {
                    url
                  }
                }
              }
              ... on PrismicPricesresellerpagenewDataBodyProgramBenefits {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  description {
                    richText
                  }
                  category_title_3 {
                    richText
                  }
                  category_title_2 {
                    richText
                  }
                  category_title_1 {
                    richText
                  }
                  icon {
                    url
                  }
                }
                items {
                  benefit_title {
                    richText
                  }
                  referral_benefit_check
                  solution_benefit_check
                }
              }
              ... on PrismicPricesresellerpagenewDataBodyTrustedByCompanies {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  contact_text {
                    richText
                  }
                  button_text {
                    richText
                  }
                  button_link {
                    url
                  }
                  heading {
                    richText
                  }
                  image {
                    alt
                    url
                  }
                }
                items {
                  partners {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PricingResellerPageNew;
