import React from 'react';
import PropTypes from 'prop-types';
import Style from './BenefitTable.module.scss';
import { RichText } from 'prismic-reactjs';
import cross from '../../images/cross.svg';
import check from '../../images/check.svg';

const BenefitTable = ({
  primary_feature_heading,
  primary_feature_title,
  primary_for_advance_plan,
  primary_for_business_plan,
  primary_for_free_plan,
  primary_for_small_plan,
  items,
}) => {
  return (
    <div className={Style.BenefitTable}>
      <div className={Style.accordionItem}>
        <div className={Style.heading}>
          <RichText render={primary_feature_heading} />
        </div>
        <div className={Style.content}>
          {items.slice(0, 2)?.map((item, index) => {
            return (
              <>
                {item?.feature_title && (
                  <div
                    className={
                      index % 2 === 0
                        ? Style.featuretitleRow
                        : Style.featureTitleRow
                    }
                    key={index}
                  >
                    <div className={Style.featuretitle}>
                      <RichText render={item?.feature_title?.richText} />
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_free_plan === true ? (
                        <img src={`${check}`} alt="check" />
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_small_plan === true ? (
                        <img src={`${check}`} alt="check" />
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_business_plan === true ? (
                        <img src={`${check}`} alt="check" />
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_advance_plan === true ? (
                        <img src={`${check}`} alt="check" />
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                  </div>
                )}
              </>
            );
          })}
          {primary_feature_title.length > 0 && (
            <div className={Style.featuretitleRow}>
              <div className={Style.featuretitle}>
                <RichText render={primary_feature_title} />
              </div>
              <div className={Style.lawsforplan}>
                <RichText render={primary_for_free_plan} />
              </div>
              <div className={Style.lawsforplan}>
                <RichText render={primary_for_small_plan} />
              </div>
              <div className={Style.lawsforplan}>
                <RichText render={primary_for_business_plan} />
              </div>
              <div className={Style.lawsforplan}>
                <RichText render={primary_for_advance_plan} />
              </div>
            </div>
          )}
          {items.slice(2, 4)?.map((item, index) => {
            return (
              <>
                {item?.feature_title && (
                  <div
                    className={
                      index % 2 === 0
                        ? Style.featureTitleRow
                        : Style.featuretitleRow
                    }
                    key={index}
                  >
                    <div className={Style.featuretitle}>
                      <RichText render={item?.feature_title?.richText} />
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_free_plan === true ? (
                        <img src={`${check}`} alt="check" />
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_small_plan === true ? (
                        <img src={`${check}`} alt="check" />
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_business_plan === true ? (
                        <img src={`${check}`} alt="check" />
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                    <div className={Style.lawsforplan}>
                      {item?.for_advance_plan === true ? (
                        <img src={`${check}`} alt="check" />
                      ) : (
                        <img src={`${cross}`} alt="cross" />
                      )}
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

BenefitTable.propTypes = {
  primary_feature_heading: PropTypes.array.isRequired,
  primary_feature_sub_heading: PropTypes.array.isRequired,
  primary_feature_title: PropTypes.array.isRequired,
  primary_for_advance_plan: PropTypes.array.isRequired,
  primary_for_business_plan: PropTypes.array.isRequired,
  primary_for_free_plan: PropTypes.array.isRequired,
  primary_for_small_plan: PropTypes.array.isRequired,
  primary_laws_for_plan: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
};

export default BenefitTable;
