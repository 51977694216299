import React from 'react';
import { any } from 'prop-types';
import styles from './TrustedByCompanies.module.scss';
import { RichText } from 'prismic-reactjs';
import Swiper from 'react-id-swiper';
import Image from '../../../../components/Image/Image';
import Button, { VARIANT } from '../../../../components/Button/Button';

const TrustedByCompanies = (datas) => {
  const { primary, items } = datas;

  const params = {
    slidesPerView: 2,
    spaceBetween: 30,

    breakpoints: {
      640: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 5,
      },
      1199: {
        slidesPerView: 7,
      },
    },
  };

  return (
    <>
      <div className={styles.trusted_companies_section_wrap}>
        <div className={styles.container}>
          <div className={styles.heading}>
            <RichText render={primary?.heading?.richText} />
          </div>
          <div className={styles.contact_text}>
            <RichText render={primary?.contact_text?.richText} />
          </div>

          <div className={styles.image}>
            <Image image={primary?.image} />
            {items.length > 0 && (
              <div className={styles.partners}>
                <Swiper {...params}>
                  {items?.map((item, index) => {
                    return (
                      <div className={styles.slide} key={index}>
                        <Image image={item?.partners} />
                      </div>
                    );
                  })}
                </Swiper>
              </div>
            )}
            {primary?.title?.richText.length === 0 && (
              <div className={styles.freecoursebutton}>
                <Button
                  to={primary?.button_link?.url}
                  variant={VARIANT.PRIMARY}
                >
                  <RichText render={primary?.button_text?.richText} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {primary?.title?.richText.length > 0 && (
        <div className={styles.buttoncontainer}>
          <div className={styles.buttonsubcontainer}>
            <div className={styles.buttontext}>
              <RichText render={primary?.title?.richText} />
            </div>
            <Button to={primary?.button_link?.url} variant={VARIANT.PRIMARY}>
              <RichText render={primary?.button_text?.richText} />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

TrustedByCompanies.propTypes = {
  datas: any,
};

export default TrustedByCompanies;
