import React from 'react';
import PropTypes from 'prop-types';
import Style from './BannerCustomization.module.scss';
import { RichText } from 'prismic-reactjs';
import cross from '../../images/cross.svg';
import check from '../../images/check.svg';

const BannerCustomization = ({ primary, items }) => {
  const {
    heading,
    feature_title,
    for_free_plan,
    for_small_plan,
    for_business_plan,
    for_advance_plan,
  } = primary;

  return (
    <>
      <div className={Style.BannerCustomization}>
        <div className={Style.content}>
          <div className={Style.heading}>
            <RichText render={heading.richText} />
          </div>
          <div className={Style.featuretitleRow}>
            <div className={Style.featuretitle}>
              <RichText render={feature_title.richText} />
            </div>
            <div className={Style.lawsforplan}>
              <RichText render={for_free_plan.richText} />
            </div>
            <div className={Style.lawsforplan}>
              <RichText render={for_small_plan.richText} />
            </div>
            <div className={Style.lawsforplan}>
              {for_business_plan.richText.length > 0 ? (
                <RichText render={for_business_plan.richText} />
              ) : (
                <img src={`${check}`} alt="check" />
              )}
            </div>
            <div className={Style.lawsforplan}>
              {for_advance_plan.richText.length > 0 ? (
                <RichText render={for_advance_plan.richText} />
              ) : (
                <img src={`${check}`} alt="check" />
              )}
            </div>
          </div>
          {items?.map((item, index) => {
            return (
              <>
                <div
                  className={
                    index % 2 === 1
                      ? Style.featuretitleRow
                      : Style.featureTitleRow
                  }
                  key={index}
                >
                  <div className={Style.featuretitle}>
                    <RichText render={item?.feature_title?.richText} />
                  </div>
                  <div className={Style.lawsforplan}>
                    {item?.for_free_plan === true ? (
                      <img src={`${check}`} alt="check" />
                    ) : (
                      <img src={`${cross}`} alt="cross" />
                    )}
                  </div>
                  <div className={Style.lawsforplan}>
                    {item?.for_small_plan === true ? (
                      <img src={`${check}`} alt="check" />
                    ) : (
                      <img src={`${cross}`} alt="cross" />
                    )}
                  </div>
                  <div className={Style.lawsforplan}>
                    {item?.for_business_plan === true ? (
                      <img src={`${check}`} alt="check" />
                    ) : (
                      <img src={`${cross}`} alt="cross" />
                    )}
                  </div>
                  <div className={Style.lawsforplan}>
                    {item?.for_advance_plan === true ? (
                      <img src={`${check}`} alt="check" />
                    ) : (
                      <img src={`${cross}`} alt="cross" />
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

BannerCustomization.propTypes = {
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default BannerCustomization;
