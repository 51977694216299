import React from 'react';
import styles from './HeroNew.module.scss';
import { object } from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Button, { VARIANT } from '../../../../components/Button/Button';

const HeroNew = ({ primary }) => {
  const {
    previewimage: previewImage,
    title,
    description,
    buttonlink,
    buttontext,
    subtitle,
  } = primary;

  return (
    <div className={styles.hero}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.sub_title}>
            <RichText render={subtitle?.richText} />
          </div>
          <div className={styles.title}>
            <RichText render={title?.richText} />
          </div>
          <div className={styles.descr}>
            <RichText render={description?.richText} />
          </div>

          <div className={styles.buttonWrapper}>
            <Button
              variant={VARIANT.PRIMARY}
              to={buttonlink?.richText[0]?.text}
            >
              <RichText render={buttontext?.richText} />
            </Button>
          </div>
        </div>

        <div className={styles.imageWrapper}>
          <div className={styles.img_wrap}>
            <img
              className={styles.image}
              src={previewImage?.url}
              alt={previewImage?.alt || 'image'}
              loading="eager"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

HeroNew.propTypes = {
  primary: object,
  handleMoveToWhatYouNeed: () => {},
};

export default HeroNew;
